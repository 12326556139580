import img from "../../images/other_services/levigatura_.jpg";
import Service from "../../components/Service";
import {pageStyles} from '../../styles/style';
import Footer from "../../components/Footer";
import DownbarS from "../../components/down_footer_s";
import Navbar from "../../components/Navbar";
import Banner from "../../components/Banner";
import Works from "../../components/Works";
import { Helmet } from "react-helmet";
import * as React from "react";
import "../../styles/main.css";
import FloatButton from "../../components/FloatButton";

// data

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <FloatButton />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Manutenpul</title>
        <meta name="keywords" content="impresa di pulizie milano, impresa di pulizie milano prezzi, impresa pulizie costi milano, impresa di pulizie cesano boscone, impresa di sanificazione, impresa di pulizie lodi" />
      </Helmet>
      <Navbar></Navbar>
      <DownbarS></DownbarS>
      <Banner pageName="Lucidatura e Levigatura " prepageName="Servizio" postpageName="Lucidatura e Levigatura marmo e parquet"/>
      <Service
        title="Lucidatura E Levigatura Marmo e Parquet"
        text="Lucidatura e levigatura marmo e parquet
        Un aspetto migliore alla tua casa o impresa? 
        Non fare brutta figura con i tuoi ospiti o clienti. 
        Il pavimento lucidato e levigato con Manutenpul è la soluzione migliore per te."
        img={img}
      >
      <br />
      <br />
      Con Manutenpul potrai avere
      <lu>
        <li>posa, lucidatura e finitura parquet</li>
        <li>lucidatura e levigatura di pavimenti in marmo</li>
        <li>Manutenpul per ogni vostra necessità sarà a vostra disposizione.</li>
      </lu>
      </Service>
      <Works />
      <Footer></Footer>
    </main>
  );
};

export default IndexPage;
